import { BrowserClient } from '@sentry/browser';
import { environment } from '../../../environments/environment';
import { State } from '@ngrx/store';
import * as Sentry from '@sentry/browser';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class ErrorService {
    constructor(state) {
        this.state = state;
        this.client = new BrowserClient({
            dsn: environment.sentryDSN,
            integrations: [
                new Sentry.Integrations.Breadcrumbs({
                    console: false,
                    fetch: true,
                    sentry: true,
                    xhr: true
                }),
                new Sentry.Integrations.GlobalHandlers({
                    onerror: true,
                    onunhandledrejection: true
                })
            ]
        });
    }
    report(error, breadcrumb) {
        console.log(error);
        const { user, tenant } = this.state.getValue();
        Sentry.addBreadcrumb(Object.assign({}, (breadcrumb || {}), { user: (user || null), tenant: (tenant || null) }));
        Sentry.withScope(scope => {
            scope.setUser({
                id: String(user.id),
                email: user.emailAddress,
            });
            Sentry.captureException(error);
        });
    }
    log(message, user, data) {
        Sentry.withScope(scope => {
            scope.setUser({
                id: String(user.id),
                email: user.emailAddress,
            });
            scope.setExtras(data);
            Sentry.captureMessage(message, Sentry.Severity.Info);
        });
    }
}
ErrorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ErrorService_Factory() { return new ErrorService(i0.ɵɵinject(i1.State)); }, token: ErrorService, providedIn: "root" });
