import { MetaActions, PaymentMethodsActions } from '../actions';
import { GetPaymentMethodForView } from '../../_service-proxies/service-proxies';

export const paymentMethods = (state = [], { type, data }: { type: string, data: GetPaymentMethodForView[] }) => {
  if (type === PaymentMethodsActions.SET_PAYMENT_METHODS) {
    return data;
  }

  if (type === MetaActions.CLEAR_DATA) {
    return null;
  }

  return state;
};
