import { MetaActions, SelectedAppointmentActions } from '../actions';

export const selectedAppointment = (state = null, { type, data }: { type: string, data: any }) => {
  if (type === SelectedAppointmentActions.SET_SELECTED_APPOINTMENT) {
    return data;
  }

  if (type === MetaActions.LOGOUT) {
    return null;
  }

  return state;
};
