import { IUserLoginInfoDto } from '../../_service-proxies/service-proxies';
import { UserActions } from '../actions';

export const user = (state = {}, { type, data }: { type: string, data: IUserLoginInfoDto }) => {
  if (type === UserActions.SET_USER) {
    return data;
  }

  return state;
};
