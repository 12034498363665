import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild } from '@angular/router';
import { TokenExpirationService } from '../../_services/token-expiration/token-expiration.service';
import { NavController } from '@ionic/angular';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private navCtrl: NavController,
    private tokenExpirationService: TokenExpirationService
  ) { }

  async canActivate() {
    const hasValidToken = await this.tokenExpirationService.hasValidToken();

    if (!hasValidToken) {
      this.navCtrl.navigateRoot(['/login']);
    }

    return hasValidToken;
  }

  async canActivateChild() {
    const hasValidToken = await this.tokenExpirationService.hasValidToken();

    if (!hasValidToken) {
      this.navCtrl.navigateRoot(['/login']);
    }

    return hasValidToken;
  }
}
