import { LocalAppointmentsActions } from '../actions';

export const localAppointments = (state = [], { type, data }: { type: string, data: any }) => {
  if (!state) {
    state = [];
  }

  if (!Array.isArray(state)) {
    state = [];
  }

  if (type === LocalAppointmentsActions.ADD_APPOINTMENT) {
    const newState = (state || []);

    newState.push(data);

    return newState;
  }

  if (type === LocalAppointmentsActions.REMOVE_APPOINTMENT) {
    return (state || []).filter(appointment => appointment.mobileId !== data.mobileId);
  }

  return state;
};
