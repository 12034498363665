import { AccessTokenActions, MetaActions } from '../actions';

export const accessToken = (state = null, { type, data }: { type: string, data: string }) => {
  if (type === AccessTokenActions.SET_ACCESS_TOKEN) {
    return data;
  }

  if (type === MetaActions.LOGOUT) {
    return null;
  }

  return state;
};
