import * as tslib_1 from "tslib";
import { TokenExpirationService } from '../../_services/token-expiration/token-expiration.service';
import { NavController } from '@ionic/angular';
export class AuthGuard {
    constructor(navCtrl, tokenExpirationService) {
        this.navCtrl = navCtrl;
        this.tokenExpirationService = tokenExpirationService;
    }
    canActivate() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const hasValidToken = yield this.tokenExpirationService.hasValidToken();
            if (!hasValidToken) {
                this.navCtrl.navigateRoot(['/login']);
            }
            return hasValidToken;
        });
    }
    canActivateChild() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const hasValidToken = yield this.tokenExpirationService.hasValidToken();
            if (!hasValidToken) {
                this.navCtrl.navigateRoot(['/login']);
            }
            return hasValidToken;
        });
    }
}
