import { AppState } from './../../app.state';
import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { State } from '@ngrx/store';

@Injectable()
export class TenantIdInterceptor implements HttpInterceptor {
  constructor(private state: State<AppState>) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const { tenant } = this.state.getValue();

    if (!tenant) { return next.handle(req); }

    const modified = req.clone({
      setHeaders: {
        'Abp.TenantId': tenant.id
      }
    });

    return next.handle(modified);
  }
}
