import { MetaActions, EncryptedAccessTokenActions } from '../actions';

export const encryptedAccessToken = (state = null, { type, data }: { type: string, data: string }) => {
  if (type === EncryptedAccessTokenActions.SET_ENCRYPTED_ACCESS_TOKEN) {
    return data;
  }

  if (type === MetaActions.LOGOUT) {
    return null;
  }

  return state;
};

