import { NgModule, ErrorHandler, Injectable, InjectionToken } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { StoreModule, ActionReducer, ActionReducerMap } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AbpModule } from 'abp-ng2-module/dist/src';
import { StorageSyncEffects, storageSync } from './_reducers/storage';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import * as reducers from './_reducers';
import { ServiceProxyModule } from './_service-proxies/service-proxies.module';
import { API_BASE_URL } from './_service-proxies/service-proxies';
import { environment } from '../environments/environment';
import { AbpHttpInterceptor } from 'abp-ng2-module/dist/src/abpHttpInterceptor';
import { TenantIdInterceptor } from './_interceptors/tenantId';
import { EnvironmentInterceptor } from './_interceptors/environment';
import { AuthInterceptor } from './_interceptors/auth';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IonicStorageModule } from '@ionic/storage';
import { ConnectivityStatusActions, SelectedCustomerActions, SelectedAppointmentActions } from './_reducers/actions';
import { CacheModule } from 'ionic-cache';

import * as Sentry from '@sentry/browser';
import { AuthGuard } from './_guards/auth/auth.guard';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AppState } from './app.state';

Sentry.init({
  dsn: environment.sentryDSN,
  integrations: [
    new Sentry.Integrations.Breadcrumbs({
      console: !environment.production,
      fetch: true,
      sentry: true,
      xhr: true
    }),
    new Sentry.Integrations.GlobalHandlers({
      onerror: true,
      onunhandledrejection: true
    })
  ],
  transport: Sentry.Transports.FetchTransport
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    console.log(error);

    Sentry.captureException(error.originalError || error);

    throw error;
  }
}

export const storageSyncReducer = storageSync({
  keys: [
    'accessToken',
    'appointments',
    'contracts',
    'contractTemplates',
    'countyTaxRates',
    'customers',
    'draftTransactions',
    'customerNotes',
    'customerAttachments',
    'reviews',
    'nearbyCustomers',
    'opportunityCycles',
    'paymentMethods',
    'productCatalogs',
    'products',
    'encryptedAccessToken',
    'lenders',
    'localAppointmentAttachments',
    'localAppointments',
    'localFollowUpNotes',
    'localApptResults',
    'signatures',
    'tasks',
    'tenant',
    'quotes',
    'todaysProjectAppointments',
    'user'
  ],
  ignoreActions: [
    ConnectivityStatusActions.SET_CONNECTIVITY_STATUS,
    SelectedCustomerActions.SET_SELECTED_CUSTOMER,
    SelectedAppointmentActions.SET_SELECTED_APPOINTMENT
  ], // Don't sync when these actions occur
  hydratedStateKey: 'hydrated', // Add this key to the state
  onSyncError: (error: any) => {
    try {
      Sentry.captureException(error);
    } catch (err) {
      console.log(err);
    }
  }
});

export function storageMetaReducer(reducer: ActionReducer<any>): ActionReducer<any, any> {
  return storageSyncReducer(reducer);
}

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<AppState>>('root reducer');

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    StoreModule.forRoot(REDUCER_TOKEN, { metaReducers: [storageMetaReducer], initialState: { hydrated: false } }),
    EffectsModule.forRoot([StorageSyncEffects]),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ServiceProxyModule,
    AbpModule,
    FontAwesomeModule,
    IonicStorageModule.forRoot(),
    CacheModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    AuthGuard,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TenantIdInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: EnvironmentInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AbpHttpInterceptor,
      multi: true
    },
    {
      provide: API_BASE_URL,
      useFactory: () => environment.api.production
    },
    {
      provide: REDUCER_TOKEN,
      useValue: reducers
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
