export const AccessTokenActions = {
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN'
};

export const EncryptedAccessTokenActions = {
  SET_ENCRYPTED_ACCESS_TOKEN: 'SET_ENCRYPTED_ACCESS_TOKEN'
};

export const ConnectivityStatusActions = {
  SET_CONNECTIVITY_STATUS: 'SET_CONNECTIVITY_STATUS'
};

export const TenantActions = {
  SET_TENANT: 'SET_TENANT',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS'
};

export const AddressNotificationsActions = {
  SET_ADDRESS_NOTIFICATIONS: 'SET_ADDRESS_NOTIFICATIONS'
};

export const UserActions = {
  SET_USER: 'SET_USER'
};

export const MetaActions = {
  LOGOUT: 'LOGOUT',
  CLEAR_DATA: 'CLEAR_DATA'
};

export const SelectedCustomerActions = {
  SET_SELECTED_CUSTOMER: 'SET_SELECTED_CUSTOMER'
};

export const SelectedAppointmentActions = {
  SET_SELECTED_APPOINTMENT: 'SET_SELECTED_APPOINTMENT'
};

export const ContractTemplateActions = {
  SET_CONTRACT_TEMPLATES: 'SET_CONTRACT_TEMPLATES'
};

export const ContractsActions = {
  SET_CONTRACTS: 'SET_CONTRACTS',
  ADD_CONTRACT: 'ADD_CONTRACT'
};

export const ReviewsActions = {
  SET_REVIEWS: 'SET_REVIEWS'
};

export const NearbyCustomerActions = {
  SET_NEARBY_CUSTOMERS: 'SET_NEARBY_CUSTOMERS'
};

export const AppointmentActions = {
  SET_APPOINTMENTS: 'SET_APPOINTMENTS',
  UPDATE_APPOINTMENT: 'UPDATE_APPOINTMENT'
};

export const CustomerActions = {
  SET_CUSTOMERS: 'SET_CUSTOMERS'
};

export const LocalAppointmentAttachmentActions = {
  ADD_ATTACHMENT: 'ADD_ATTACHMENT',
  REMOVE_ATTACHMENT: 'REMOVE_ATTACHMENT',
};

export const LocalAppointmentsActions = {
  ADD_APPOINTMENT: 'ADD_APPOINTMENT',
  REMOVE_APPOINTMENT: 'REMOVE_APPOINTMENT',
};

export const LocalFollowUpNotesActions = {
  ADD_FOLLOW_UP_NOTE: 'ADD_FOLLOW_UP_NOTE',
  REMOVE_FOLLOW_UP_NOTE: 'REMOVE_FOLLOW_UP_NOTE',
};

export const CustomerNotesActions = {
  SET_CUSTOMER_NOTES: 'SET_CUSTOMER_NOTES'
};

export const CustomerAttachmentsActions = {
  SET_CUSTOMER_ATTACHMENTS: 'SET_CUSTOMER_ATTACHMENTS'
};

export const OpportunityCyclesActions = {
  SET_OPPORTUNITY_CYCLES: 'SET_OPPORTUNITY_CYCLES'
};

export const QuotesActions = {
  SET_QUOTES: 'SET_QUOTES',
  ADD_QUOTE: 'ADD_QUOTE'
};

export const ProductsActions = {
  SET_PRODUCTS: 'SET_PRODUCTS'
};

export const TasksActions = {
  SET_TASKS: 'SET_TASKS'
};

export const PaymentMethodsActions = {
  SET_PAYMENT_METHODS: 'SET_PAYMENT_METHODS'
};

export const DraftTransactionsActions = {
  ADD_DRAFT_TRANSACTION: 'ADD_DRAFT_TRANSACTION',
  REMOVE_DRAFT_TRANSACTION: 'REMOVE_DRAFT_TRANSACTION',
  UPDATE_DRAFT_TRANSACTION: 'UPDATE_DRAFT_TRANSACTION'
};

export const LocalApptResultActions = {
  ADD_LOCAL_APPT_RESULT: 'ADD_LOCAL_APPT_RESULT',
  REMOVE_LOCAL_APPT_RESULT: 'REMOVE_LOCAL_APPT_RESULT'
};

export const SignaturesActions = {
  ADD_OR_UPDATE_SIGNATURE: 'ADD_OR_UPDATE_SIGNATURE',
  REMOVE_SIGNATURES_FOR_TRANSHEADER: 'REMOVE_SIGNATURES_FOR_TRANSHEADER'
};

export const TodaysProjectAppointmentsActions = {
  SET_TODAYS_APPOINTMENTS: 'SET_TODAYS_APPOINTMENTS'
};

export const APIEnvironmentActions = {
  SET_ENVIRONMENT_PRODUCTION: 'SET_ENVIRONMENT_PRODUCTION',
  SET_ENVIRONMENT_SANDBOX: 'SET_ENVIRONMENT_SANDBOX',
};

export const LenderActions = {
  SET_LENDERS: 'SET_LENDERS'
};

export const ProductCatalogActions = {
  SET_PRODUCT_CATALOGS: 'SET_PRODUCT_CATALOGS'
};

export const CountyTaxRateActions = {
  SET_COUNTY_TAX_RATES: 'SET_COUNTY_TAX_RATES'
};
