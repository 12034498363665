import { LocalApptResultActions } from '../actions';
import { IAppointmentResultDto } from '../../_service-proxies/service-proxies';

export const localApptResults = (state = [], { type, data }: { type: string, data: IAppointmentResultDto }) => {
  if (type === LocalApptResultActions.ADD_LOCAL_APPT_RESULT) {
    state.push(data);

    return state;
  }

  if (type === LocalApptResultActions.REMOVE_LOCAL_APPT_RESULT) {
    return (state || []).filter((result: IAppointmentResultDto) => result.appointmentId === data.appointmentId);
  }

  return state;
};
