import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { API_ENVIRONMENT_KEY, API_ENVIRONMENTS, REHYDRATE_ACTION } from '../../_reducers/storage';
import { Storage } from '@ionic/storage';
import { Store } from '@ngrx/store';
import { AppState } from '../../app.state';

@Injectable({
  providedIn: 'root'
})
export class APIService {

  private api: string = environment.api.production;
  private pdfAPI: string = environment.pdfAPI.production;
  private cacheAPI: string = environment.cache.production;

  constructor(
    private store: Store<AppState>
  ) {
    const env =
      window.localStorage.getItem(API_ENVIRONMENT_KEY) ||
      API_ENVIRONMENTS.PRODUCTION_V3;

    this.api =
      environment.api[(env || API_ENVIRONMENTS.PRODUCTION_V3).toLowerCase()];
    this.pdfAPI =
      environment.pdfAPI[(env || API_ENVIRONMENTS.PRODUCTION_V3).toLowerCase()];
    this.cacheAPI =
      environment.cache[(env || API_ENVIRONMENTS.PRODUCTION_V3).toLowerCase()];
  }

  public getAPIURL(): string {
    return this.api;
  }

  public getPDFAPIURL(): string {
    return this.pdfAPI;
  }

  public getCacheApi(): string {
    return this.cacheAPI;
  }

  public updateEnvironment(env: string) {
    this.api =
      environment.api[(env || API_ENVIRONMENTS.PRODUCTION_V3).toLowerCase()];
    this.pdfAPI =
      environment.pdfAPI[(env || API_ENVIRONMENTS.PRODUCTION_V3).toLowerCase()];
    this.cacheAPI =
      environment.cache[(env || API_ENVIRONMENTS.PRODUCTION_V3).toLowerCase()];

    window.localStorage.setItem(API_ENVIRONMENT_KEY, env);

    this.store.dispatch({ type: REHYDRATE_ACTION });
  }

}
