import { ContractsActions, MetaActions } from '../actions';

export const contracts = (state = [], { type, data }: { type: string, data: any[] }) => {
  if (type === ContractsActions.SET_CONTRACTS) {
    return data;
  }

  if (type === MetaActions.CLEAR_DATA) {
    return [];
  }

  return state;
};
