import { MetaActions, ProductsActions } from '../actions';

export const products = (state = [], { type, data }: { type: string, data: any }) => {
  if (type === ProductsActions.SET_PRODUCTS) {
    return data;
  }

  if (type === MetaActions.CLEAR_DATA) {
    return [];
  }

  return state;
};
