import { AbpHttpInterceptor } from 'abp-ng2-module/dist/src/abpHttpInterceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import * as ApiServiceProxies from './service-proxies';

@NgModule({
  providers: [
    ApiServiceProxies.WebLeadsServiceProxy,
    ApiServiceProxies.LoansServiceProxy,
    ApiServiceProxies.RegionsServiceProxy,
    ApiServiceProxies.RegionalManagersServiceProxy,
    ApiServiceProxies.ZipCountiesServiceProxy,
    ApiServiceProxies.ServicesServiceProxy,
    ApiServiceProxies.TransDetailsServiceProxy,
    ApiServiceProxies.LendersServiceProxy,
    ApiServiceProxies.TransHeadersServiceProxy,
    ApiServiceProxies.AppointmentsServiceProxy,
    ApiServiceProxies.PaymentMethodsServiceProxy,
    ApiServiceProxies.DCMTasksServiceProxy,
    ApiServiceProxies.TerritoriesServiceProxy,
    ApiServiceProxies.CustomerAttachmentsServiceProxy,
    ApiServiceProxies.TextMessagesServiceProxy,
    ApiServiceProxies.CustomerNotesServiceProxy,
    ApiServiceProxies.ReviewsServiceProxy,
    ApiServiceProxies.ProductImagesServiceProxy,
    ApiServiceProxies.ProductConfigurationsServiceProxy,
    ApiServiceProxies.OpportunityCyclesServiceProxy,
    ApiServiceProxies.CustomersServiceProxy,
    ApiServiceProxies.VendorsServiceProxy,
    ApiServiceProxies.ReportingCategoriesServiceProxy,
    ApiServiceProxies.ProductsServiceProxy,
    ApiServiceProxies.ChannelsServiceProxy,
    ApiServiceProxies.StoreHoursServiceProxy,
    ApiServiceProxies.SalesAppointmentTypesServiceProxy,
    ApiServiceProxies.ProductCategoriesServiceProxy,
    ApiServiceProxies.AdvertisingCostsServiceProxy,
    ApiServiceProxies.SecondarySourcesServiceProxy,
    ApiServiceProxies.PrimarySourcesServiceProxy,
    ApiServiceProxies.AuditLogServiceProxy,
    ApiServiceProxies.CachingServiceProxy,
    ApiServiceProxies.CommonLookupServiceProxy,
    ApiServiceProxies.EditionServiceProxy,
    ApiServiceProxies.HostSettingsServiceProxy,
    ApiServiceProxies.InstallServiceProxy,
    ApiServiceProxies.LanguageServiceProxy,
    ApiServiceProxies.NotificationServiceProxy,
    ApiServiceProxies.OrganizationUnitServiceProxy,
    ApiServiceProxies.PermissionServiceProxy,
    ApiServiceProxies.ProfileServiceProxy,
    ApiServiceProxies.RoleServiceProxy,
    ApiServiceProxies.SessionServiceProxy,
    ApiServiceProxies.TenantServiceProxy,
    ApiServiceProxies.TenantDashboardServiceProxy,
    ApiServiceProxies.TenantSettingsServiceProxy,
    ApiServiceProxies.TimingServiceProxy,
    ApiServiceProxies.UserServiceProxy,
    ApiServiceProxies.UserLinkServiceProxy,
    ApiServiceProxies.UserLoginServiceProxy,
    ApiServiceProxies.WebLogServiceProxy,
    ApiServiceProxies.AccountServiceProxy,
    ApiServiceProxies.TokenAuthServiceProxy,
    ApiServiceProxies.TenantRegistrationServiceProxy,
    ApiServiceProxies.HostDashboardServiceProxy,
    ApiServiceProxies.PaymentServiceProxy,
    ApiServiceProxies.DemoUiComponentsServiceProxy,
    ApiServiceProxies.InvoiceServiceProxy,
    ApiServiceProxies.SubscriptionServiceProxy,
    ApiServiceProxies.InstallServiceProxy,
    ApiServiceProxies.UiCustomizationSettingsServiceProxy,
    ApiServiceProxies.MobileSalesRepServiceServiceProxy,
    ApiServiceProxies.ContractTemplatesServiceProxy,
    ApiServiceProxies.ContractTemplateImagesServiceProxy,
    ApiServiceProxies.UserEventsServiceProxy,
    ApiServiceProxies.ProductCatalogsServiceProxy,
    ApiServiceProxies.CountyTaxRatesServiceProxy,
    { provide: HTTP_INTERCEPTORS, useClass: AbpHttpInterceptor, multi: true }
  ]
})
export class ServiceProxyModule { }
