import { Injectable } from '@angular/core';
import { BrowserClient } from '@sentry/browser';
import { environment } from '../../../environments/environment';
import { State } from '@ngrx/store';
import { AppState } from '../../app.state';
import * as Sentry from '@sentry/browser';
import { IUserLoginInfoDto } from '../../_service-proxies/service-proxies';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private client: BrowserClient;

  constructor(private state: State<AppState>) {
    this.client = new BrowserClient({
      dsn: environment.sentryDSN,
      integrations: [
        new Sentry.Integrations.Breadcrumbs({
          console: false,
          fetch: true,
          sentry: true,
          xhr: true
        }),
        new Sentry.Integrations.GlobalHandlers({
          onerror: true,
          onunhandledrejection: true
        })
      ]
    });
  }

  public report(error: Error, breadcrumb?: any) {
    console.log(error);

    const { user, tenant } = this.state.getValue();

    Sentry.addBreadcrumb({
      ...(breadcrumb || {}),
      user: (user || null),
      tenant: (tenant || null)
    });

    Sentry.withScope(scope => {
      scope.setUser({
        id: String(user.id),
        email: user.emailAddress,
      });

      Sentry.captureException(error);
    });

  }

  public log(message: string, user: IUserLoginInfoDto, data: any) {
    Sentry.withScope(scope => {
      scope.setUser({
        id: String(user.id),
        email: user.emailAddress,
      });

      scope.setExtras(data);

      Sentry.captureMessage(message, Sentry.Severity.Info);
    });
  }

}
