import { CustomerNotesActions, MetaActions } from '../actions';

export const customerNotes = (state = [], { type, data }: { type: string, data: any }) => {
  if (type === CustomerNotesActions.SET_CUSTOMER_NOTES) {
    return data;
  }

  if (type === MetaActions.CLEAR_DATA) {
    return null;
  }

  return state;
};
