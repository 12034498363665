import { AddressNotificationsActions, MetaActions } from '../actions';

export const addressNotifications = (state = [], { type, data }: { type: string, data: any }) => {
  if (type === AddressNotificationsActions.SET_ADDRESS_NOTIFICATIONS) {
    return data;
  }

  if (type === MetaActions.CLEAR_DATA) {
    return null;
  }

  return state;
};
