import * as tslib_1 from "tslib";
import { State, Store } from '@ngrx/store';
import * as jwt_decode from 'jwt-decode';
import { MetaActions } from '../../_reducers/actions';
import { NavController } from '@ionic/angular';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
import * as i2 from "@ionic/angular";
export class TokenExpirationService {
    constructor(state, store, navCtrl) {
        this.state = state;
        this.store = store;
        this.navCtrl = navCtrl;
    }
    start() {
        this.tokenExpirationInterval = window.setInterval(() => tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (yield this.hasValidToken()) {
                return;
            }
            this.store.dispatch({ type: MetaActions.LOGOUT });
            this.navCtrl.navigateRoot(['/login']);
            this.stop();
        }), 1000 * 60 * 5); // Every 5 minute
    }
    stop() {
        window.clearInterval(this.tokenExpirationInterval);
    }
    hasValidToken() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            const { accessToken } = this.state.getValue();
            if (!accessToken) {
                return false;
            }
            const jwt = jwt_decode(accessToken);
            const currentTime = Date.now() / 1000;
            if (jwt.exp < currentTime) {
                return false;
            }
            const validWithServer = yield this.validateTokenWithServer(accessToken);
            return true;
        });
    }
    validateTokenWithServer(accessToken) {
        // TODO: Call server with token to validate
        return Promise.resolve(false);
    }
}
TokenExpirationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TokenExpirationService_Factory() { return new TokenExpirationService(i0.ɵɵinject(i1.State), i0.ɵɵinject(i1.Store), i0.ɵɵinject(i2.NavController)); }, token: TokenExpirationService, providedIn: "root" });
