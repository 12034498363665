import { MetaActions, CountyTaxRateActions } from '../actions';

export const countyTaxRates = (state = [], { type, data }: { type: string, data: any[] }) => {
  if (type === CountyTaxRateActions.SET_COUNTY_TAX_RATES) {
    return data;
  }

  if (type === MetaActions.CLEAR_DATA) {
    return null;
  }

  return state;
};
