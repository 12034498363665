import { LocalAppointmentAttachmentActions } from '../actions';

export const localAppointmentAttachments = (state = [], { type, data }: { type: string, data: any }) => {
  if (!state) {
    state = [];
  }

  if (!Array.isArray(state)) {
    state = [];
  }

  if (type === LocalAppointmentAttachmentActions.ADD_ATTACHMENT) {
    const newState = (state || []);

    newState.push(data);

    return newState;
  }

  if (type === LocalAppointmentAttachmentActions.REMOVE_ATTACHMENT) {
    return (state || []).filter(attachment => attachment.uuid !== data.uuid);
  }

  return state;
};
