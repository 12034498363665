import { version } from "../../package.json";

export const environment = {
  production: true,
  api: {
    production: "https://ww360-api-v3-m.azurewebsites.net",
    production_v3: "https://ww360-api-v3-m.azurewebsites.net",
    sandbox: "https://ww360-sandbox-api.azurewebsites.net",
    sandbox_v3: "https://ww360-v3-sandbox-api.azurewebsites.net",
    devtest: "https://ww360-devtest-api.azurewebsites.net/",
  },
  pdfAPI: {
    production: "https://pdf-api.ww360.app",
    production_v3: "https://pdf-api.ww360.app",
    sandbox:
      "https://sandbox-ww360-pdf-api.azurewebsites.net/api/generatetransheaderpdf",
    sandbox_v3:
      "https://sandbox-ww360-pdf-api.azurewebsites.net/api/generatetransheaderpdf",
    devtest:
      "https://sandbox-ww360-pdf-api.azurewebsites.net/api/generatetransheaderpdf",
  },
  cache: {
    production: "https://ww360.azurewebsites.net/cache",
    production_v3: "https://ww360-v3.azurewebsites.net/cache",
    sandbox: "https://sandbox-ww360.azurewebsites.net/cache",
    sandbox_v3: "https://sandbox-ww360-v3.azurewebsites.net/cache",
    devtest: "https://devtest-ww360.azurewebsites.net/cache",
  },
  sentryDSN: "https://1cf691dbadc2485a9f133ad362a02a7f@sentry.io/1339685",
  version,
  googleMapsKey: "AIzaSyCgCpxiXvTCsRoVGA5hKsYPe15Iolh-q2E",
};
