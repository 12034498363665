import { Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth/auth.guard';
const ɵ0 = () => import("./_modules/home/home.module.ngfactory").then(m => m.HomePageModuleNgFactory), ɵ1 = () => import("./_modules/login/login.module.ngfactory").then(m => m.LoginPageModuleNgFactory);
const routes = [
    { path: '', redirectTo: 'home', pathMatch: 'full' },
    { path: 'home', loadChildren: ɵ0, canActivate: [AuthGuard] },
    { path: 'login', loadChildren: ɵ1 },
    { path: 'dashboard', loadChildren: './_modules/dashboard/dashboard.module#DashboardPageModule', canActivate: [AuthGuard] },
    { path: 'calendar', loadChildren: './_modules/calendar/calendar.module#CalendarPageModule', canActivate: [AuthGuard] },
    { path: 'commission-info', loadChildren: './_modules/commission-info/commission-info.module#CommissionInfoPageModule' },
    { path: 'contract-list', loadChildren: './_modules/contract-list/contract-list.module#ContractListPageModule' },
    { path: 'invoice', loadChildren: './_modules/invoice/invoice.module#InvoicePageModule', canActivate: [AuthGuard] },
    { path: 'appointments', loadChildren: './_modules/appointments/appointments.module#AppointmentsPageModule', canActivate: [AuthGuard] },
    { path: 'customer-list', loadChildren: './_modules/customer-list/customer-list.module#CustomerListPageModule', canActivate: [AuthGuard] },
    {
        path: 'customer-profile',
        loadChildren: './_modules/customer-profile/customer-profile.module#CustomerProfilePageModule',
        canActivate: [AuthGuard]
    },
    { path: 'opportunities', loadChildren: './_modules/opportunities/opportunities.module#OpportunitiesPageModule', canActivate: [AuthGuard] },
    { path: 'appointment', loadChildren: './_modules/appointment/appointment.module#AppointmentPageModule', canActivate: [AuthGuard] },
    { path: 'contract', loadChildren: './_modules/contract/contract.module#ContractPageModule' },
    { path: 'general-contract', loadChildren: './_modules/general-contract/general-contract.module#GeneralContractPageModule' },
    { path: 'today-appointments', loadChildren: './_modules/today-appointments/today-appointments.module#TodayAppointmentsPageModule' },
    { path: 'tasks', loadChildren: './_modules/tasks/tasks.module#TasksPageModule' },
];
export class AppRoutingModule {
}
export { ɵ0, ɵ1 };
