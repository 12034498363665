import { Component } from '@angular/core';

import { Platform, NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { State } from '@ngrx/store';
import { AppState } from './app.state';
import { ErrorService } from './_services/error';
import { TokenExpirationService } from './_services/token-expiration/token-expiration.service';
import { CacheService } from 'ionic-cache';
import { Plugins } from '@capacitor/core';

const { SplashScreen } = Plugins;

declare var abp: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private storage: Storage,
    private state: State<AppState>,
    private errorService: ErrorService,
    private navCtrl: NavController,
    private tokenExpirationService: TokenExpirationService,
    private cache: CacheService
  ) {
    this.initializeApp();

    abp.message.error = console.log;
  }

  initializeApp() {
    let hydrated = false;

    this.platform.ready()
      .then(() => this.storage.ready())
      .then(() => {
        this.state.subscribe({
          error: (error) => {
            this.errorService.report(error, { page: 'app', action: 'initialize' });

            this.navCtrl.navigateRoot(['/login']);
          },
          next: async state => {
            if (!state.hydrated || hydrated) { return; }

            if (await this.tokenExpirationService.hasValidToken()) {
              this.navCtrl.navigateRoot(['/home']);
            } else {
              this.navCtrl.navigateRoot(['/login']);
            }

            hydrated = true;

            this.cache.setDefaultTTL(60 * 60);

            SplashScreen.hide();
          }
        });
      });
  }
}
