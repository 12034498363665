import { TenantActions } from '../actions';

export const tenant = (
  state = null,
  { type, data }: { type: string, data: any }
) => {
  if (type === TenantActions.SET_TENANT) {
    return data;
  }

  if (type === TenantActions.UPDATE_SETTINGS) {
    state.settings = data;

    return state;
  }

  return state;
};
