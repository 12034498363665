import { AppointmentActions, MetaActions } from '../actions';

export const appointments = (state = [], { type, data }: { type: string, data: any }) => {
  if (type === AppointmentActions.SET_APPOINTMENTS) {
    return data;
  }

  if (type === AppointmentActions.UPDATE_APPOINTMENT) {
    return (state || []).map(appt => appt.appointment.id === data.appointment.id ? data : appt);
  }

  if (type === MetaActions.CLEAR_DATA) {
    return null;
  }

  return state;
};
