import { MetaActions, ContractTemplateActions } from '../actions';

export const contractTemplates = (state = [], { type, data }: { type: string, data: any[] }) => {
  if (type === ContractTemplateActions.SET_CONTRACT_TEMPLATES) {
    return data;
  }

  if (type === MetaActions.CLEAR_DATA) {
    return null;
  }

  return state;
};
