import { MetaActions, SelectedCustomerActions } from '../actions';

export const selectedCustomer = (state = null, { type, data }: { type: string, data: any }) => {
  if (type === SelectedCustomerActions.SET_SELECTED_CUSTOMER) {
    return data;
  }

  if (type === MetaActions.LOGOUT) {
    return null;
  }

  return state;
};
