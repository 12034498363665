import { environment } from '../../../environments/environment';
import { API_ENVIRONMENT_KEY, API_ENVIRONMENTS, REHYDRATE_ACTION } from '../../_reducers/storage';
import { Store } from '@ngrx/store';
import * as i0 from "@angular/core";
import * as i1 from "@ngrx/store";
export class APIService {
    constructor(store) {
        this.store = store;
        this.api = environment.api.production;
        this.pdfAPI = environment.pdfAPI.production;
        this.cacheAPI = environment.cache.production;
        const env = window.localStorage.getItem(API_ENVIRONMENT_KEY) ||
            API_ENVIRONMENTS.PRODUCTION_V3;
        this.api =
            environment.api[(env || API_ENVIRONMENTS.PRODUCTION_V3).toLowerCase()];
        this.pdfAPI =
            environment.pdfAPI[(env || API_ENVIRONMENTS.PRODUCTION_V3).toLowerCase()];
        this.cacheAPI =
            environment.cache[(env || API_ENVIRONMENTS.PRODUCTION_V3).toLowerCase()];
    }
    getAPIURL() {
        return this.api;
    }
    getPDFAPIURL() {
        return this.pdfAPI;
    }
    getCacheApi() {
        return this.cacheAPI;
    }
    updateEnvironment(env) {
        this.api =
            environment.api[(env || API_ENVIRONMENTS.PRODUCTION_V3).toLowerCase()];
        this.pdfAPI =
            environment.pdfAPI[(env || API_ENVIRONMENTS.PRODUCTION_V3).toLowerCase()];
        this.cacheAPI =
            environment.cache[(env || API_ENVIRONMENTS.PRODUCTION_V3).toLowerCase()];
        window.localStorage.setItem(API_ENVIRONMENT_KEY, env);
        this.store.dispatch({ type: REHYDRATE_ACTION });
    }
}
APIService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function APIService_Factory() { return new APIService(i0.ɵɵinject(i1.Store)); }, token: APIService, providedIn: "root" });
