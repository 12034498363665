import { Injectable } from '@angular/core';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store, Action, ActionReducer } from '@ngrx/store';
import { Observable, of, defer } from 'rxjs';

import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Storage } from '@ionic/storage';
import { mergeMap } from 'rxjs/operators';
import { fromPromise } from 'rxjs/observable/fromPromise';

export const STORAGE_KEY = 'NSIS_APP_STATE';

export const API_ENVIRONMENTS = {
  PRODUCTION: 'PRODUCTION',
  PRODUCTION_V3: 'PRODUCTION_V3',
  SANDBOX: 'SANDBOX',
  SANDBOX_V3: 'SANDBOX_V3',
  DEVTEST: 'DEVTEST',
};

export const API_ENVIRONMENT_KEY = 'WW360_ENVIRONMENT';

export const REHYDRATE_ACTION = '[AppState] Rehydrate';

export const storage = new Storage({});

// get/setNested inspired by
// https://github.com/mickhansen/dottie.js
function getNested(obj: any, path: string): any {
  if (obj !== null && path) {
    // Recurse into the object.
    const parts = path.split('.').reverse();
    while (obj != null && parts.length) {
      obj = obj[parts.pop()];
    }
  }
  return obj;
}

function setNested(obj: any, path: string, value: any): any {
  if (obj != null && path) {
    let pieces = path.split('.'),
      current = obj,
      piece, i,
      length = pieces.length;

    for (i = 0; i < length; i++) {
      piece = pieces[i];
      if (i === length - 1) {
        current[piece] = value;
      } else if (!current[piece]) {
        current[piece] = {};
      }
      current = current[piece];
    }
  }

  return obj;
}

function fetchState(): Promise<{}> {
  return storage
    .get(API_ENVIRONMENT_KEY)
    .then((env: string) => {
      let key = STORAGE_KEY;

      if (env && env !== API_ENVIRONMENTS.PRODUCTION_V3) {
        key += `_${env}`;
      }

      return storage.get(key).then(s => s || {});
    })
    .catch(err => { });
}

function saveState(state: any, keys: string[]): Promise<void> {
  // Pull out the portion of the state to save.
  if (keys) {
    state = keys.reduce((acc, k) => {
      const val = getNested(state, k);
      if (val) {
        setNested(acc, k, val);
      }
      return acc;
    }, {});
  }

  return storage
    .get(API_ENVIRONMENT_KEY)
    .then((env: string) => {
      let key = STORAGE_KEY;

      if (env && env !== API_ENVIRONMENTS.PRODUCTION_V3) {
        key += `_${env}`;
      }

      return storage.set(key, state);
    })
    .catch(err => { });
}

export const StorageSyncActions = {
  HYDRATED: 'NSIS_APP_HYDRATED'
};

@Injectable()
export class StorageSyncEffects {

  @Effect() hydrate$: Observable<any> = defer(() =>
    fromPromise(fetchState())
      .map(state => ({
        type: StorageSyncActions.HYDRATED,
        payload: state
      })).catch(e => {
        console.warn(`error fetching data from store for hydration: ${e}`);

        return of({
          type: StorageSyncActions.HYDRATED,
          payload: {}
        });
      }));

  @Effect()
  rehydrateState$ = this.actions$
    .pipe(
      ofType(REHYDRATE_ACTION),
      mergeMap(() => defer(() =>
        fromPromise(fetchState())
          .map(state => {
            console.log('Rehydrated State');

            return {
              type: StorageSyncActions.HYDRATED,
              payload: state
            };
          })
          .catch(e => {
            console.warn(`error fetching data from store for hydration: ${e}`);

            return of({
              type: StorageSyncActions.HYDRATED,
              payload: {}
            });
          })
      )
      )
    );

  constructor(
    private actions$: Actions
  ) { }
}

export interface StorageSyncOptions {
  keys?: string[];
  ignoreActions?: string[];
  hydratedStateKey?: string;
  onSyncError?: (err: any) => void;
}

const defaultOptions: StorageSyncOptions = {
  keys: [],
  ignoreActions: [],
  onSyncError: (err) => { }
};

export function storageSync(options?: StorageSyncOptions) {
  const { keys, ignoreActions, hydratedStateKey, onSyncError } = Object.assign({}, defaultOptions, options || {});

  ignoreActions.push(StorageSyncActions.HYDRATED);
  ignoreActions.push('@ngrx/store/init');
  ignoreActions.push('@ngrx/effects/init');
  ignoreActions.push('@ngrx/store/update-reducers');

  const hydratedState: any = {};

  return function storageSyncReducer(reducer: ActionReducer<any>) {
    return (state: any, action: any) => {
      const { type, payload } = action;

      if (type === StorageSyncActions.HYDRATED) {
        state = Object.assign({}, state, payload);
        if (hydratedStateKey) {
          hydratedState[hydratedStateKey] = true;
        }
      }

      const nextState = Object.assign({}, reducer(state, action), hydratedState);

      if (ignoreActions.indexOf(type) === -1) {
        saveState(nextState, keys).catch(err => onSyncError(err));
      }

      return nextState;
    };
  };
}
