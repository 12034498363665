import { GetDCMTaskForView } from 'src/app/_service-proxies/service-proxies';
import { TasksActions, MetaActions } from '../actions';

export const tasks = (state = [], { type, data }: { type: string, data: GetDCMTaskForView[] }) => {
  if (type === TasksActions.SET_TASKS) {
    return data;
  }

  if (type === MetaActions.LOGOUT) {
    return [];
  }

  return state;
};
