import { MetaActions, LenderActions } from '../actions';
import { GetLenderForView } from '../../_service-proxies/service-proxies';

export const lenders = (state = [], { type, data }: { type: string, data: GetLenderForView[] }) => {
  if (type === LenderActions.SET_LENDERS) {
    return data;
  }

  if (type === MetaActions.CLEAR_DATA) {
    return null;
  }

  return state;
};
