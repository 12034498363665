import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_guards/auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', loadChildren: () => import('./_modules/home/home.module').then( m => m.HomePageModule), canActivate: [AuthGuard]},
  { path: 'login', loadChildren: () => import('./_modules/login/login.module').then( m => m.LoginPageModule)},
  { path: 'dashboard', loadChildren: './_modules/dashboard/dashboard.module#DashboardPageModule', canActivate: [AuthGuard] },
  { path: 'calendar', loadChildren: './_modules/calendar/calendar.module#CalendarPageModule', canActivate: [AuthGuard] },
  { path: 'commission-info', loadChildren: './_modules/commission-info/commission-info.module#CommissionInfoPageModule' },
  { path: 'contract-list', loadChildren: './_modules/contract-list/contract-list.module#ContractListPageModule' },
  { path: 'invoice', loadChildren: './_modules/invoice/invoice.module#InvoicePageModule', canActivate: [AuthGuard] },
  { path: 'appointments', loadChildren: './_modules/appointments/appointments.module#AppointmentsPageModule', canActivate: [AuthGuard] },
  { path: 'customer-list', loadChildren: './_modules/customer-list/customer-list.module#CustomerListPageModule', canActivate: [AuthGuard] },
  {
    path: 'customer-profile',
    loadChildren: './_modules/customer-profile/customer-profile.module#CustomerProfilePageModule',
    canActivate: [AuthGuard]
  },
  { path: 'opportunities', loadChildren: './_modules/opportunities/opportunities.module#OpportunitiesPageModule', canActivate: [AuthGuard] },
  { path: 'appointment', loadChildren: './_modules/appointment/appointment.module#AppointmentPageModule', canActivate: [AuthGuard] },
  { path: 'contract', loadChildren: './_modules/contract/contract.module#ContractPageModule' },
  { path: 'general-contract', loadChildren: './_modules/general-contract/general-contract.module#GeneralContractPageModule' },
  { path: 'today-appointments', loadChildren: './_modules/today-appointments/today-appointments.module#TodayAppointmentsPageModule' },
  { path: 'tasks', loadChildren: './_modules/tasks/tasks.module#TasksPageModule' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
