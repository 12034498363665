import { LocalFollowUpNotesActions } from '../actions';

export const localFollowUpNotes = (state = [], { type, data }: { type: string, data: any }) => {
  if (!state) {
    state = [];
  }

  if (!Array.isArray(state)) {
    state = [];
  }

  if (type === LocalFollowUpNotesActions.ADD_FOLLOW_UP_NOTE) {
    const newState = (state || []);

    newState.push(data);

    return newState;
  }

  if (type === LocalFollowUpNotesActions.REMOVE_FOLLOW_UP_NOTE) {
    return (state || []).filter(note => note.mobileId !== data.mobileId);
  }

  return state;
};
