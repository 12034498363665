import { SignaturesActions, MetaActions } from '../actions';
// import { ISignature } from '../../_pages/contract/contract.page';

export const signatures = (state: any[] = [], { type, data }: { type: string, data: any }) => {
  if (state === null) {
    state = [];
  }

  if (type === SignaturesActions.ADD_OR_UPDATE_SIGNATURE) {
    const alreadyHasSignature = (state || []).findIndex(s =>
      s.transHeaderId === data.transHeaderId &&
      s.field === data.field
    ) > -1;

    if (alreadyHasSignature) {
      state = state.map(s =>
        (
          s.transHeaderId === data.transHeaderId &&
          s.field === data.field
        )
        ? data
        : s
      );
    } else {
      state.push(data);
    }
  }

  if (type === SignaturesActions.REMOVE_SIGNATURES_FOR_TRANSHEADER) {
    state = state.filter(s => s.transHeaderId === data.transHeaderId);
  }

  if (type === MetaActions.CLEAR_DATA) {
    return null;
  }

  return state;
};
