import { QuotesActions, MetaActions } from '../actions';

export const quotes = (state = [], { type, data }: { type: string, data: any }) => {
  if (type === QuotesActions.SET_QUOTES) {
    return data;
  }

  if (type === MetaActions.CLEAR_DATA) {
    return null;
  }

  return state;
};
