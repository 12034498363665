import { ErrorHandler, InjectionToken } from '@angular/core';
import { ActionReducer, ActionReducerMap } from '@ngrx/store';
import { storageSync } from './_reducers/storage';
import * as reducers from './_reducers';
import { environment } from '../environments/environment';
import { ConnectivityStatusActions, SelectedCustomerActions, SelectedAppointmentActions } from './_reducers/actions';
import * as Sentry from '@sentry/browser';
Sentry.init({
    dsn: environment.sentryDSN,
    integrations: [
        new Sentry.Integrations.Breadcrumbs({
            console: !environment.production,
            fetch: true,
            sentry: true,
            xhr: true
        }),
        new Sentry.Integrations.GlobalHandlers({
            onerror: true,
            onunhandledrejection: true
        })
    ],
    transport: Sentry.Transports.FetchTransport
});
export class SentryErrorHandler {
    constructor() { }
    handleError(error) {
        console.log(error);
        Sentry.captureException(error.originalError || error);
        throw error;
    }
}
const ɵ0 = (error) => {
    try {
        Sentry.captureException(error);
    }
    catch (err) {
        console.log(err);
    }
};
export const storageSyncReducer = storageSync({
    keys: [
        'accessToken',
        'appointments',
        'contracts',
        'contractTemplates',
        'countyTaxRates',
        'customers',
        'draftTransactions',
        'customerNotes',
        'customerAttachments',
        'reviews',
        'nearbyCustomers',
        'opportunityCycles',
        'paymentMethods',
        'productCatalogs',
        'products',
        'encryptedAccessToken',
        'lenders',
        'localAppointmentAttachments',
        'localAppointments',
        'localFollowUpNotes',
        'localApptResults',
        'signatures',
        'tasks',
        'tenant',
        'quotes',
        'todaysProjectAppointments',
        'user'
    ],
    ignoreActions: [
        ConnectivityStatusActions.SET_CONNECTIVITY_STATUS,
        SelectedCustomerActions.SET_SELECTED_CUSTOMER,
        SelectedAppointmentActions.SET_SELECTED_APPOINTMENT
    ],
    hydratedStateKey: 'hydrated',
    onSyncError: ɵ0
});
export function storageMetaReducer(reducer) {
    return storageSyncReducer(reducer);
}
export const REDUCER_TOKEN = new InjectionToken('root reducer');
const ɵ1 = () => environment.api.production, ɵ2 = reducers;
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2 };
