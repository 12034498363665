import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { APIService } from '../../_services/api';
import { environment } from '../../../environments/environment.prod';
import { State } from '@ngrx/store';
import { AppState } from '../../app.state';
import * as Url from 'url-parse';

@Injectable()
export class EnvironmentInterceptor implements HttpInterceptor {
  constructor(private apiService: APIService, private state: State<AppState>) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isAPIURL =
      req.url.toLowerCase().startsWith(environment.api.production) ||
      req.url.toLowerCase().startsWith(environment.api.production_v3) ||
      req.url.toLowerCase().startsWith(environment.api.sandbox) ||
      req.url.toLowerCase().startsWith(environment.api.sandbox_v3) ||
      req.url.toLowerCase().startsWith(environment.api.devtest);

    if (!isAPIURL) {
      return next.handle(req);
    }

    const parsed = Url(req.url);
    const actualURL = Url(this.apiService.getAPIURL());

    parsed.set('port', actualURL.port);
    parsed.set('protocol', actualURL.protocol);
    parsed.set('hostname', actualURL.hostname);

    const clone = req.clone({
      url: parsed.toString(),
    });

    return next.handle(clone);
  }
}
