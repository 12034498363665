import { DraftTransactionsActions } from '../actions';
import { uniqBy } from 'lodash';
import { TransHeaderDto } from '../../_service-proxies/service-proxies';
import { API_ENVIRONMENTS, API_ENVIRONMENT_KEY, STORAGE_KEY, storage } from '../storage';

const persistStorage = async (draftTransactions: TransHeaderDto[]) => {
  const env = await storage.get(API_ENVIRONMENT_KEY);

  let key = STORAGE_KEY;

  if (env && env !== API_ENVIRONMENTS.PRODUCTION_V3) {
    key += `_${env}`;
  }

  const state = await storage.get(key).then((s) => s || {});

  state.draftTransactions = JSON.parse(JSON.stringify(draftTransactions));

  await storage.set(key, state);
};

export const draftTransactions = (state = [], { type, data }: { type: string, data: TransHeaderDto }) => {
  if (type === DraftTransactionsActions.ADD_DRAFT_TRANSACTION) {
    // @ts-ignore
    delete data.appointment;

    state.push(data);

    persistStorage(state).then(() => console.log('Draft transaction saved to storage')).catch((e) => console.log(e));

    return state;
  }

  if (type === DraftTransactionsActions.REMOVE_DRAFT_TRANSACTION) {
    console.log(state);
    console.log(data);

    const newState = [ ...(state || []) ].filter((transaction: TransHeaderDto) => transaction.mobileId !== data.mobileId);

    console.log(newState);

    persistStorage(newState)
      .then(() => console.log("Draft transaction saved to storage"))
      .catch((e) => console.log(e));

    return newState;
  }

  if (type === DraftTransactionsActions.UPDATE_DRAFT_TRANSACTION) {
    // @ts-ignore
    delete data.appointment;

    const alreadyExists = (state || []).findIndex((dt: TransHeaderDto) => dt.mobileId === data.mobileId) > -1;

    // Only map over if updating a draft which has already been saved
    if (alreadyExists) {
     state = (state || []).map((dt: TransHeaderDto) => {
        const isDtToUpdate = dt.mobileId === data.mobileId;

        if (isDtToUpdate) { return data; }

        return dt;
      });
    }


    const uniqData = uniqBy(state, 'mobileId');

    persistStorage(uniqData)
      .then(() => console.log("Draft transaction saved to storage"))
      .catch((e) => console.log(e));

    return uniqData;
  }

  return state;
};
