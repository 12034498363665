import { TenantActions, TodaysProjectAppointmentsActions } from '../actions';

export const todaysProjectAppointments = (
  state = { date: null, appointments: [] },
  { type, data }: { type: string, data: any }
) => {
  if (type === TodaysProjectAppointmentsActions.SET_TODAYS_APPOINTMENTS) {
    return data;
  }

  return state;
};
