import { Injectable } from '@angular/core';
import { State, Store } from '@ngrx/store';
import { AppState } from '../../app.state';
import * as jwt_decode from 'jwt-decode';
import { MetaActions } from '../../_reducers/actions';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class TokenExpirationService {
  private tokenExpirationInterval: number;

  constructor(
    private state: State<AppState>,
    private store: Store<AppState>,
    private navCtrl: NavController
  ) { }

  public start() {
    this.tokenExpirationInterval = window.setInterval(async () => {
      if (await this.hasValidToken()) { return; }

      this.store.dispatch({ type: MetaActions.LOGOUT });

      this.navCtrl.navigateRoot(['/login']);

      this.stop();
    }, 1000 * 60 * 5); // Every 5 minute
  }

  public stop() {
    window.clearInterval(this.tokenExpirationInterval);
  }

  public async hasValidToken(): Promise<boolean> {
    const { accessToken } = this.state.getValue();

    if (!accessToken) {
      return false;
    }

    const jwt: any = jwt_decode(accessToken);

    const currentTime = Date.now() / 1000;

    if (jwt.exp < currentTime) {
      return false;
    }

    const validWithServer = await this.validateTokenWithServer(accessToken);

    return true;
  }

  private validateTokenWithServer(accessToken: string): Promise<boolean> {
    // TODO: Call server with token to validate

    return Promise.resolve(false);
  }

}
