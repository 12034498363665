import { NearbyCustomerActions, MetaActions } from '../actions';

export const nearbyCustomers = (state = null, { type, data }: { type: string, data: any[] }) => {
  if (type === NearbyCustomerActions.SET_NEARBY_CUSTOMERS) {
    return data;
  }

  if (type === MetaActions.CLEAR_DATA) {
    return null;
  }

  return state;
};
