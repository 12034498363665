import { CustomerActions, MetaActions } from '../actions';

export const customers = (state = [], { type, data }: { type: string, data: any[] }) => {
  if (type === CustomerActions.SET_CUSTOMERS) {
    return data;
  }

  if (type === MetaActions.CLEAR_DATA) {
    return null;
  }

  return state;
};
